.App {
  text-align: center;
}

body {
  font-family: "Karla";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: #b98d58 !important;
}

.login_paper.MuiPaper-elevation {
  display: flex;
  flex-direction: column;
  padding: 40px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}




/* dashboard */
.cards-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}


.card-paper.MuiPaper-elevation {
  width: 100%;
  background-color: rgb(46, 45, 61);
}

.cards-grid .cards {
  padding: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

}

.cards-grid .cards span.count {
  font-size: 50px;
  color: #FFF;

}

.cards-grid .cards .icon-d {
  color: #fff;
  font-size: 80px;
}

.cards-grid .cards p,
.cards-grid .cards h2 {
  color: #FFF;
}


@media screen and (max-width: 991.98px) {
  .cards-grid {
    grid-template-columns: repeat(2, 1fr);

  }
}

@media screen and (max-width: 767.98px) {
  .cards-grid {
    grid-template-columns: repeat(1, 1fr);

  }
}